import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios'
import BPHeader from './TPCOMPONENTS/bp-header'
import BPSideMenu from './TPCOMPONENTS/bp-sidemenu'
import { StudentActions } from './TPCOMPONENTS/bp-reusables'
import { IoIosArrowDown } from 'react-icons/io';
import { UserDataContext } from '../Contexts/user-data';
import { UserInterfaceContext } from '../Contexts/ui-context';
import { Navigate } from 'react-router-dom';
import { FaUserGraduate } from 'react-icons/fa';
import Receipt from '../Pages/finance/receipt';
import { Alert } from '../Components/reusables';

function BPStudentDetails() {
  const {Progress,setLoading, setShowReceipt, AlertNotice} = useContext(UserInterfaceContext)
    const {userId, } = useContext(UserDataContext)
    const {setUserId, setTerm, setYear,setIncome_id  } = useContext(UserDataContext)
    const [userData, setUserData] = useState([])
    const [userFinancialData, setUserFinancialData] = useState([])
    const [userPaymentReceipts, setUserPaymentReceipts] = useState([])


    function formatDateToDayAndDate(isoDate) {
      const date = new Date(isoDate);
      const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
      const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      return `${dayName}, ${formattedDate}`;
  }


    useEffect(()=>{
        //console.log('student id in details : ', userId)
        Progress('30%')
            // axios.get('https://new-softesc-backend.onrender.com/students'
        axios.get('https://new-softesc-backend.onrender.com/students'
        ,{
          params:{
            userId : userId,
          }
        })
      .then((response)=>{
        Progress('100%')
        setUserData(response.data)
        ////console.log(response.data)
        //console.log('userdata',userData)
      })
      .catch((error)=>{
        Progress('0%')
        //console.log(error)
      })
      },[userId])
    
    
      useEffect(()=>{
        //console.log('student id in details : ', userId)
        Progress('30%')
            // axios.get('https://new-softesc-backend.onrender.com/students'
        axios.get('https://new-softesc-backend.onrender.com/finance/studentTerm'
        ,{
          params:{
            refNumber : userId,
          }
        })
      .then((response)=>{
        Progress('100%')
        setUserFinancialData(response.data)
        ////console.log(response.data)
        //console.log('userdata',userData)
      })
      .catch((error)=>{
        Progress('0%')
        //console.log(error)
      })
      },[userId])
     
      useEffect(()=>{
        setLoading(true)
        Progress('30%')
        axios.get('https://new-softesc-backend.onrender.com/finance/income',{
          params:{
            userId : userId,
          }
        })
        .then((response)=>{
          Progress('100%')
          setUserPaymentReceipts(response.data || [])
          setLoading(false)
        })
        .catch((error)=> {
          Progress('0%')
          //console.log(error)
        })
      },[])
    


      if(userId===''){
        return <Navigate to='/BPStudents'/>
      }
      function GenerateStageName (stage){
        if(stage===-4){
            return('PRE-SCHOOL-CRECHE')
        }
        else if(stage===-3){
            return('NURSERY 1')
        }
        else if(stage===-2){
            return('NURSERY 2')
        }
        else if(stage===-1){
            return('KINDERGARTEN 1')
        }
        else if(stage===0){
            return('KINDERGARTEN 2')
        }
        else if(stage===1){
            return('CLASS 1')
        }
        else if(stage===2){
            return('CLASS 2')
        }
        else if(stage===3){
            return('CLASS 3')
        }
        else if(stage===4){
            return('CLASS 4')
        }
        else if(stage===5){
            return('CLASS 5')
        }
        else if(stage===6){
            return('CLASS 6')
        }
        else if(stage===7){
            return('CLASS 7 / JHS 1')
        }
        else if(stage===8){
            return('CLASS 8 / JHS 2')
        }
        else if(stage===9){
            return('CLASS 9 / JHS 3')
        }
      }

  return (
    <div className='App'>
      <BPSideMenu/>
      <div className='main-menu'>
        <BPHeader/>
        <div className='conent-loader student-details'>
            <StudentActions/>
            {/* <h3 className='class-level'>STUDENT DETAILS : <b style={{color: 'yellow'}}>{userId}</b></h3> */}
            <div className="user-header">
                <div className="overlay">
                </div>
                <div className="img-container">
              <FaUserGraduate id='user-details-icon'/>
            </div>
            <div className="personal-details">
              <label htmlFor="">Student ID : {userData[0]?.refNumber} </label>
              <label htmlFor="">Name : {userData[0]?.fullName} </label>
              <label htmlFor="">{GenerateStageName(userData[0]?.stage)} </label>
            </div>
            </div>

        <div>
           
        {userData.length>1? <p style={{color: 'red'}}>Choose Student from student Data</p>: userData.map((userData, index)=>(
          <div key={index} className='info'>
            

            <div  className="personal-info">
                  <h3>Personal Information</h3>
                 
                  <label htmlFor="">Gender : {userData.gender} </label>
                  <label htmlFor="">Class Teacher : {userData.classTeacher} </label>
                  {/* <label htmlFor="">School Fees : </label> <label htmlFor="">Paid 300.00 out of 450.00</label> */}
                  <label htmlFor="">House : {userData.house} </label>
                  <label htmlFor="">Address : {userData.presentAddress} </label>
                  <label htmlFor="">Date of Birth : {userData.dob} </label>
                  <label htmlFor="">Father's Name : {userData.fatherName} </label>
                  <label htmlFor="">Father's Contact  : {userData.fatherPhone}  </label>
                  <label htmlFor="">Mother's Name : {userData.motherName} </label>
                  <label htmlFor="">Mother's Contact : {userData.motherPhone} </label>
                  <label htmlFor="">Email : {userData.email}  </label>
              </div>
              
              <div className="financial-info">
              <h3>Financial Information</h3>

              {userFinancialData.length>0?userFinancialData.map((financeData, index)=>(
                <div key={index} style={{position : 'relative',marginBottom: '1rem' }}>
                <span className='financial-info-head' htmlFor="" >Year : {financeData.academicYear} | Term : {financeData.academicTerm}  </span>
                <b>Stage :.................. {GenerateStageName(financeData.stageValue)}  </b>
                <b>Total Fees :........... {financeData.financialData.totalFees?financeData.financialData.totalFees:0} </b>
                <b>Total Paid :........... {financeData.financialData.paidFees?financeData.financialData.paidFees:0}</b>
                <b>Total Owing :....... {financeData.financialData.totalFees - financeData.financialData.paidFees} </b>
                <b> Financial Status :. {(financeData.financialData.totalFees - financeData.financialData.paidFees)>0? <b style={{color: 'red'}}>OWING</b> : <b style={{color:'green'}}>PAID</b> } </b>
                 <br /><hr/>
            </div>
              ))    
              :null}
              </div>
              
              <div className="payment-receipt-info">
                  <h3>Payment Receipts - Counts : {userPaymentReceipts.length}</h3>

                  {(userPaymentReceipts || []).map((receipt, index) => (
                    <label key={receipt.refNumber.id || index}
                    onClick={()=>{
                      setShowReceipt(true)
                      setTerm(receipt?.academicTerm)
                      setYear(receipt?.academicYear)
                      setIncome_id(receipt._id)
                      setUserId(receipt.refNumber.id)
                    }}
                    >
                      Receipt RefNumber: {receipt.incomeRefNumber} <b>||</b> Paid For: {receipt.incomeType} <b>||</b> Amount: {receipt.amount} <b>||</b> Period: {receipt.academicYear} - {receipt.academicTerm} - {formatDateToDayAndDate(receipt.dateAdded)}
                    </label>
                  ))}
              </div>
              <div className="other-info">
                  <h3>Other Info</h3>
                  <label htmlFor="">Status : {userData?.userStatus} </label>
              </div>
          </div>
       ))
      }
      </div>
          </div>
        <Receipt/>
      </div>
    </div>
  )
}

export default BPStudentDetails