import React, { useState, useEffect, useContext } from 'react'
import { FaEye, FaEdit, FaTrash, FaUser } from 'react-icons/fa'; // Example icons from Font Awesome
// import Attendance from '../attendance/attendance';
import '../student/user.css'
import { NavLink } from 'react-router-dom';
import { TeachersActions, DeletePrompt } from '../../Components/reusables';
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'
import { UserInterfaceContext } from '../../Contexts/ui-context';
import axios from 'axios';
import { UserDataContext } from '../../Contexts/user-data';
import { IoIosArrowDown } from 'react-icons/io';
import { Loading } from '../../Components/reusables';


function AllTeachers() {
  const {Progress, showDeletePallet, setShowDeletePallet, setLoading} = useContext(UserInterfaceContext)
  const {userId,setUserId} = useContext(UserDataContext)

  const [teachers, setTeachers] = useState([])
  const [name, setName] = useState('')
  const [staffCategory, setStaffCategory] = useState('')
  const [gender, setGender] = useState('')
  const snnitPercentage = 0.13
  const taxPercentage = 0.2
  const schoolAllowance = 140
  const [sortingView, setSortingView] = useState(false)

  function toggleSortingView (){
    setSortingView(!sortingView)
  }


  
  useEffect(() => {
    setLoading(true)
    Progress('30%')
    // axios.get('https://new-softesc-backend.onrender.com/teachers')
    axios.get('https://new-softesc-backend.onrender.com/staff', {
      params:{
        name : name,
        staffCategory : staffCategory,
        gender: gender,

      }
    })
      .then((response) => {
        setLoading(false)
        Progress('100%')
        setTeachers(response.data)
        ////console.log(response.data)
    })
      .catch((error) => {
        Progress('0%')
        //console.log(error)
        
      });
  }, [name, staffCategory, gender]);

  return (
    <div className="App">
        <SideMenu/>
        <div className="main-menu">
          <Header/>
          <DeletePrompt/>

          <div className='all-users content-loader'>
      <div className='user-features'>
      <TeachersActions/>

        <div className="sorting" style={{maxHeight: sortingView?'4rem':'',overflow: 'hidden'}}>
          <div className="sorting-header" onClick={toggleSortingView}>
            <h2>Sort By</h2>

            <IoIosArrowDown id='sorting-down-arrow'/>
            </div>
          <div className="sorting-body" style={{display: sortingView?'none':'flex'}}>
          <div className="input">
            <label htmlFor="">Name: </label>
            <input 
              type="text" 
              placeholder='Enter your search Here'
              value={name}
              onChange={(e)=> {setName(e.target.value)}}
              />
          </div>
          
          <div className="input">
            <label htmlFor="">Staff Type: </label>
            <select  
              value={staffCategory}
              onChange={(e)=> {setStaffCategory(e.target.value)}}
            >
                <option value="">All </option>
                <option value="administration">Administration</option>
                <option value="teaching">Teaching</option>
                <option value="kitchen">Kitchen</option>
                <option value="security">Security</option>
                <option value="library">Library</option>
                <option value="others">Others</option>
            </select>
          </div>
          <div className="input">
            <label htmlFor="">Gender: </label>
            <select  
              value={gender}
              onChange={(e)=> {setGender(e.target.value)}}
            >
                <option value="">All </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
            </select>
          </div>
          </div>
         
        </div>
      </div>
      <div className="userfeature">
              <b>Total Fetched : {teachers.length}</b>
            </div>
      <div className="container">
      <table>
        <Loading/>
      <thead>
        <tr>
          <th id='serial-num-head'>Staff ID</th>
          <th>Photo</th>
          <th id='user-name-head'>Name</th>
          <th>Class</th>
          <th>Staff Category</th>
          <th>Gender</th>
          <th>Telephone</th>
          <th>Email</th>
          <th>
            Bank Account Information
            <div id="inner-th">
              <em id='paid'>Bank Name</em>
              <em id='balance'>Account Number</em>
            </div>
          </th>
          <th>Salary (GHS)</th>
          <th>National Id Number</th>
          <th>Status</th>
          <th id='user-actions-head'>Action</th>
        </tr>
      </thead>
      <tbody>
        {teachers.length>0?(teachers.map((teachers, index)=>(
            <tr key={index}>
            <td >{teachers.refNumber}</td>
            <td style={{textAlign: 'center', }}><FaUser id='user-icon' style={{color: '#031c3b'}}/></td>
            <td id='user-name'>{teachers.firstName + " " +" "+ teachers.lastName}</td>
            <td>{teachers.stage}</td>
            <td>{teachers.staffCategory.toUpperCase()}</td>
            <td>{teachers.gender}</td>
            <td>{teachers.telephone}</td>
            <td>{teachers.email}</td>
            <td id='inner-td'><em id='paid'>{teachers.bankName}</em> <em id='balance'>{teachers.accountNumber}</em></td>
            <td id='text-align-right'>{teachers.salary}</td>
            {/* <td id='text-align-right'>{schoolAllowance + parseFloat(teachers.salary-(snnitPercentage*teachers.salary + taxPercentage*teachers.salary)) }</td> */}
            <td>{teachers.nationalIdNumber}</td>
            <td>Active</td>
            <td id='table-actions'>
              
              <NavLink to="/TeacherDetails" onClick={async() => await setUserId(teachers.refNumber)}>
                          <FaEye id="action-see" />
                        </NavLink> 
                      <NavLink to='/TeacherUpdate' onClick={async()=> await setUserId(teachers.refNumber)}>
                          <FaEdit id='action-update'/> 
                      </NavLink>
                      <NavLink>
                        <FaTrash id='action-delete' onClick={()=>{
                          setShowDeletePallet(true); 
                          setUserId(teachers.refNumber)
                          }}/>
                </NavLink>
                </td>
          </tr>
        ))):
        (<tr>
          <td colSpan='22' style={{padding:'1rem', color:'red'}}>NO STAFF DATA TO SHOW</td>
        </tr>)
                
        }
              
      </tbody>
    </table>
      </div>
    </div>
        </div>
      </div>
    
  )
}

export default AllTeachers