import axios from "axios";
import { createContext, useEffect, useState  } from "react";


const UserDataContext = createContext();

const UserDataContextProviders = ({children})=>{
  const [userId, setUserId] = useState('')
  const [income_id, setIncome_id] = useState('')
  const [year, setYear] = useState('')
  const [term, setTerm] = useState('')

  const [selectedStudent, setSelectedStudent] = useState('')
  const [receiptMessage, setReceiptMessage] = useState('')
  // const [selectedReceipt, setSelectedReceipt] = useState('')
  return (
    <UserDataContext.Provider value={
      {

       setUserId,
       userId,     
      

       //this is for the receipt data
        income_id, 
        setIncome_id,
        term, 
        setTerm,
        year,
        setYear,

        selectedStudent,
        setSelectedStudent,
        receiptMessage,
        setReceiptMessage,
        


        }}>
      {children}
    </UserDataContext.Provider>
  )
}

export {UserDataContext, UserDataContextProviders}