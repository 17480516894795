import React, { useContext, useState, useEffect } from 'react'
import { SuperActionButtons, Alert } from '../../Components/reusables'
import { SideMenu } from '../../Components/sideMenu'
import Header from '../../Components/header'
import { UserInterfaceContext } from '../../Contexts/ui-context'
import axios from 'axios'
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; // Example icons from Font Awesome
import { IoIosArrowDown } from 'react-icons/io';
import { Loading } from '../../Components/reusables'

function AddAcademicYear() {
    const {Progress, AlertNotice, isSubmitting, setIsSubmitting, loading, setLoading} = useContext(UserInterfaceContext)
    const [academicYearName, setAcademicYearName] = useState()
    const [beginDate, setBeginDate] = useState(new Date());
    const defaultEndDate = new Date();
    defaultEndDate.setFullYear((defaultEndDate).getFullYear() + 1);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [sortingView, setSortingView] = useState(false)

    const [allAcademicYear, setAllAcademicYear] = useState([])
    const [refresh, setRefresh] = useState(false)
    
    function toggleSortingView (){
        setSortingView(!sortingView)
      }

      function formatDateToDayAndDate(isoDate) {
        const date = new Date(isoDate);
        const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
        const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        return `${dayName}, ${formattedDate}`;
    }
    useEffect(()=>{
        if (beginDate && endDate) {
            const beginYear = beginDate.getFullYear();
            const endYear = endDate.getFullYear();
            setAcademicYearName(`${beginYear}/${endYear}`);
        }      
        setIsSubmitting(false)
    },[beginDate, endDate])



    function resetInputs (){
            setAcademicYearName('');
            setBeginDate();  // or any default date
            setEndDate();    // or any default date
        
    }
    

   

    const academicYearData = {
        academicYearName : academicYearName,
        beginDate: beginDate,
        endDate:endDate,
    }
    
   

    function handleSubmitAcademicYear (e){
        setIsSubmitting(true)
        // e.preventDefault()
        if(beginDate>endDate){
            AlertNotice('Invalid End Date', false)
            return
        }
        Progress('30%')
        // axios.post('https://new-softesc-backend.onrender.com/academicYear', academicYearData )
        axios.post('https://new-softesc-backend.onrender.com/academicYear', academicYearData )
        .then((response)=>{
            AlertNotice(response.data.message,true)
            Progress('100%')
            ////console.log(response)
            //console.log('form submitted')
            setRefresh(!refresh)
            setIsSubmitting(false)


        })
        .catch((error)=>{
            AlertNotice(error.response.data.error,false)
            Progress('0%')
            //console.log('could not submit the academic year data',error)
            setIsSubmitting(false)

        }).finally(()=>{
            setIsSubmitting(false)
        })
    }

    useEffect(()=>{
        setLoading(true)
        Progress('30%')
        axios.get('https://new-softesc-backend.onrender.com/academicYear')
        .then((response)=>{
            Progress('100%')
            ////console.log(response.data)
            setAllAcademicYear(response.data)
            setLoading(false)
        })
        .catch((error)=>{
            Progress('0%')
            
            //console.log(error)
        })
    },[refresh])



    
useEffect(() => {
    setEndDate(calculateEndDate(beginDate)); // Set end date when start date changes
}, [beginDate]);

const calculateEndDate = (startDate) => {
    const currentDate = new Date(startDate);
    currentDate.setMonth(currentDate.getMonth() + 12); // Add three months to the start date
    return currentDate; // Format end date as YYYY-MM-DD
};



  return (
        <div className="App">
        <SideMenu/>
        <Alert/>
        <div className="main-menu">
            <Header/>
            <div className="content-loader">
                <SuperActionButtons/>

                <form onSubmit={handleSubmitAcademicYear}>
                <div className="academic-year-form">
                    <div className="sorting" style={{maxHeight: sortingView?'4rem':'',overflow: 'hidden'}}>
                    <div className="sorting-header" onClick={toggleSortingView}>
                    <h2>Add new Academic Year</h2>
                    <IoIosArrowDown id='sorting-down-arrow'/>
                    </div>  
                    <div className="sorting-body"  style={{display: sortingView?'none':'flex'}}>
                    <div className="input">
                        <label htmlFor="">Year Code:</label>
                        <input 
                        type="text" 
                        required
                        disabled
                        value={academicYearName}
                        onChange={(e)=>{setAcademicYearName(e.target.value)}}
                        />

                    </div>
                    <div className="input">
                        <label htmlFor="">Begin Date</label>
                        <input 
                            type="date" 
                            required
                            value={beginDate?beginDate.toISOString().split('T')[0]: null}  // Convert to string
                            onChange={(e) => { setBeginDate(new Date(e.target.value)); }}
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="">End Date: </label>
                        <input 
                            type="date"
                            required
                            value={endDate?endDate.toISOString().split('T')[0]: null}  // Convert to string
                            onChange={(e) => { setEndDate(new Date(e.target.value)); }}
                        />
                    </div>
                    </div>
                     <div id="submit-reset-buttons">
                        <button type='submit' onClick={()=>{handleSubmitAcademicYear(); setIsSubmitting(true)}} disabled={isSubmitting}>Create Year</button>
                        <button onClick={resetInputs} type='button'>Reset Inputs</button>
                    </div>
                    </div>
                   
                </div>
                </form>
                <div className="container">

                <h2 className='input sticky-left'>ANNUAL SUMMARIES</h2>

                        <table className='user-table'>
                        <Loading/>

                            <thead>
                               <tr>
                               <th>YEAR ID</th>
                                <th id='user-name-head'>YEAR NAME</th>
                                <th>START DATE</th>
                                <th>END DATE</th>
                                {/* <th style={{color: ''}}>TOTAL INCOME (GHS)</th> */}
                                {/* <th style={{color: ''}}>TOTAL EXPENDITURE (GHS)</th> */}
                                {/* <th>BALANCE C/D</th> */}
                                {/* <th>TT STUDENTS ADMITTED</th> */}
                                {/* <th>TT STUDENTS LEFT</th> */}
                                <th>STATUS</th>
                                {/* <th id='user-actions-head'>ACTION </th> */}
                               </tr>
                            </thead>
                            <tbody>
                                {
                                    allAcademicYear?.map((year, index)=>(
                                        <tr key={index}>
                                            <td>
                                                {year.refNumber}
                                            </td>
                                            <td id='user-name'>
                                                {year.academicYearName}

                                            </td>
                                            <td>
                                                {formatDateToDayAndDate(year.beginDate)}

                                            </td>
                                            <td>
                                                {formatDateToDayAndDate(year.endDate)}

                                            </td>
                                            {/* <td>
                                                {year.ttIncome}

                                            </td>
                                            <td>
                                                {year.ttExpense}

                                            </td>
                                            <td>
                                                0.000

                                            </td> */}
                                            {/* <td>
                                                {year.ttStudentsIn}

                                            </td>
                                            <td>
                                                {year.ttStudentsOut}

                                            </td> */}
              
                                            <td style={{color : year.status==='CURRENT'?'#031c3b':'#f47c2c', fontWeight: 'bolder'}}>{year.status} </td>

                                            {/* <td id='table-actions'><FaEye style={{color: 'green'}}/> <FaEdit/> <FaTrash style={{color:'red'}}/></td> */}

                                </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                </div>
            </div>
        </div>
        </div>

  )
}

export default AddAcademicYear

