import React, { useContext, useEffect, useState } from 'react'
import logo from '../student/School Crest.png'
import axios from 'axios';
import { UserDataContext } from '../../Contexts/user-data';
import { Loading } from '../../Components/reusables';
import { UserInterfaceContext } from '../../Contexts/ui-context';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef } from 'react';
import { FiDownload } from 'react-icons/fi';
import { FaEnvelope } from 'react-icons/fa'; // Example icons from Font Awesome



function Receipt() {
    const {userId, income_id, term, year, setUserId, setIncome_id, setTerm, setYear, selectedStudent, setSelectedStudent,receiptMessage, setReceiptMessage } = useContext(UserDataContext)
    const [incomeData, setIncome] = useState()
    const [studentTerm, setStudentTerm] = useState()
    const {setLoading, showReceipt, setShowReceipt, Progress, AlertNotice} = useContext(UserInterfaceContext)
    const componentRef = useRef(null); 

        const messagePayload = {
        sender: 'ADMIN-FINANCE',
        receiver: 'Parents',
        student: userId,
        channel: 'sms',
        receiptMessageData: {
            payable : studentTerm?.financialData?.totalFees??0,
            received: incomeData?.amount??0,
            totalPaid : studentTerm?.financialData?.paidFees??0,
            balance : (studentTerm?.financialData?.totalFees ??0) - (studentTerm?.financialData?.paidFees??0),
            incomeType: incomeData?.incomeType
        },
    };

        function SendReceiptMessage() {
       
    
        console.log('Sending Message:', messagePayload);
        Progress('30%');
        setLoading(true)
        axios.post('https://new-softesc-backend.onrender.com/notices', messagePayload)
            .then((response) => {
                Progress('100%');
                setLoading(false)
                AlertNotice(response.data.message, true);
            })
            .catch((error) => {
                Progress('0%');
                setLoading(false)
                AlertNotice(error?.response?.data?.error || 'Failed to send message', false);
                console.log(error);
            });
    }
              
 
     


    useEffect (()=>{
        if(userId==='' || income_id===''){
            return
        }
       FetchIncome()
       FetchStudentTerm()
    },[income_id, userId, term, year])

    function ResetParameters (){
        // setUserId('')
        setIncome_id('')
        setTerm('')
        setYear('')
    }

   async function FetchIncome(){
        if(userId==='' || income_id===''){
            return
        }
        else {
            setLoading(true)
            await axios.get('https://new-softesc-backend.onrender.com/finance/income',{
                params :{
                    userId,
                    transaction_id : income_id
                }
            })
            .then((response)=>{
                setIncome(response.data[0])
            })
            .catch((error)=>{
                console.log(error)
                setLoading(false)
            })
        }
        
    }

    async function FetchStudentTerm(){
        if(!userId || !income_id ){
            return
        }
        else{
            axios.get('https://new-softesc-backend.onrender.com/finance/studentTerm',{
                params :{
                    refNumber:userId,
                    academicTerm:term,
                    academicYear:year,
                }
            })
            .then((response)=>{
                setStudentTerm(response.data[0])
                setLoading(false)
            })
            .catch((error)=>{
                console.log(error)
                setLoading(false)
            })
        }
       
    }

    function printPDF() {
        setLoading(true)
        const input = document.getElementById('receipt');
        html2canvas(input,{
            scale: 1.5,
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a3');
                const imgWidth = pdf.internal.pageSize.getWidth();
                const imgHeight = canvas.height * imgWidth / canvas.width;
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save(incomeData?.refNumber.name + ' ' + incomeData.incomeRefNumber +' '+ 'RECEIPT.pdf');
                setLoading(false)
            });
    }



  return (
 <>
 {showReceipt? ( <div className='receipt-pallet'>
     <div className='receipt' id='receipt'>
    <Loading/>
       <div className="school-header">
            <div className="school-logo">
                <img src={logo} alt="School Logo" />
            </div>
            <div className="school-details" style={{paddingTop : '1rem'}}>
                <h3 style={{fontSize: '1.5rem', color: 'black'}}>STAR OF THE EAST SCHOOL COMPLEX</h3>
                <h4 style={{fontSize : '1.6rem', color : 'black'}}>Excellence Through Christ</h4>
                <p style={{color: 'black', fontSize : '1.1rem'}}>Location: TAIFA-NOWAY, BEHIND PRUDENTIAL BANK</p>
                <p style={{color: 'black', fontSize : '1.1rem'}}>Email: softesc.online@gmail.com</p>
                <p style={{color: 'black', fontSize : '1.1rem'}}>Tel: +233 000 000 000 / +233 000 000 000</p>
            </div>
            <div className="school-logo">
                <img src={logo} alt="School Logo" />
            </div>
        </div>
        <hr />
        <div className="receipt-info">
            <div >  <h3 style={{textAlign: 'center',backgroundColor:'black', color: 'white', padding:'0.5rem 1rem'}}>OFFICIAL RECEIPT</h3></div>
            <div>            <label>Receipt No.: <b>{incomeData?.incomeRefNumber} || {incomeData?.incomeType?.toUpperCase()}</b></label><br />
        </div>
            <div style={{fontWeight: 'normal'}}>
                <label>Academic Year: <b>{incomeData?.academicYear}</b></label>
                <label>Academic Term: <b>{incomeData?.academicTerm}</b></label>
                <label>Date: <b>{incomeData?.dateAdded ? new Date(incomeData.dateAdded).toISOString().slice(0, 10) : ''}</b></label>

            </div>
            <hr />
            <div style={{flexDirection :'column', alignContent: 'left', gap:'0.4rem'}}>
                <label>ID : <b> {(studentTerm?.refNumber.id)??'NULL'}</b></label> 
                <label>Name: <b> {(studentTerm?.refNumber.name)??'NULL'}                                     {incomeData?.incomeType}</b> </label>
            </div>
            <hr />
            {/* <br /> */}
            <div style={{flexDirection : 'column', }} className='receipt-finance-info'>
                <label htmlFor="">School Fees Payable : <b>{(studentTerm?.financialData.totalFees)??0}</b></label>
                <label htmlFor="">Amount Received : <b>{(incomeData?.amount)??0}</b></label>
                <label htmlFor="">Total Paid : <b>{(studentTerm?.financialData.paidFees)??'0.00'}</b></label>
                <label htmlFor="">Balance Fee: <b>{((studentTerm?.financialData.totalFees)-(studentTerm?.financialData.paidFees))??'0.00'}</b></label>
            </div>
            <div>
                <label htmlFor="">Received By : ............................</label>
                <label htmlFor="">Signature : ...........................</label>
                {/* <label htmlFor=""></label> */}
            </div>
        </div>
   </div>
    <div className="receipt-action">
       <div style={{backgroundColor : 'red'}} onClick={()=>{setShowReceipt(false); ResetParameters()}}> <b>x</b></div>
        <div><FiDownload onClick={()=>{printPDF()}}/></div>
        <div><FaEnvelope onClick={()=>{SendReceiptMessage()}}/></div>
    </div>
  </div>):null}
 </>
  );
}

export default Receipt