import React, { useContext, useEffect, useState } from 'react'
import { FinancialActions, Alert } from '../../Components/reusables'
import {SideMenu} from '../../Components/sideMenu'
import Header from '../../Components/header'
import axios from 'axios'
import { UserInterfaceContext } from '../../Contexts/ui-context'

function AddIncome() {
  const {Progress, AlertNotice, } = useContext(UserInterfaceContext)

      const [allStudents, setAllStudents] = useState([])
      const [academicYear, setAcademicYear] =useState([])
      const [academicTerm, setAcademicTerm] =useState([])

      const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
      const [selectedAcademicTerm, setSelectedAcademicTerm] = useState('')
      const [id, setId] = useState('')
      const [stage, setStage] = useState('')
      const [amount, setAmount] =useState(0)
      const [paymentMethod, setPaymentMethod] =useState('')
      const [incomeType, setIncomeType] =useState('')
      const [description, setDescription] =useState('')
      
      const [toggleIncomePallet, setToggleIncomePallet] = useState(true)

    const IncomeData ={
      academicYear:selectedAcademicYear,
      academicTerm: selectedAcademicTerm,
      amount : parseFloat(amount),
      paymentMethod : paymentMethod,
      incomeType : incomeType,
      description : description
    }


    const handleSubmitIncome=(e)=> {
      e.preventDefault()
      //console.log(IncomeData)
      if(IncomeData.academicTerm!==''&&IncomeData.academicYear!==''&&IncomeData.amount!==''&&IncomeData.paymentMethod!==''&&IncomeData.incomeType!==''){
        if(IncomeData.amount>0){
          Progress('30%')
      // axios.post('https://new-softesc-backend.onrender.com/finance/income', IncomeData)
      axios.post('https://new-softesc-backend.onrender.com/finance/income', IncomeData)
        .then((response) => {
          AlertNotice(response.data.message, true)
          Progress('100%')
        })
        .catch((error) => {
          AlertNotice('Failed to Add Income', false)
          Progress('0%')
        });
        }
        else {
          AlertNotice('Amount must be more than 0', false)

        }
       
      }
     else{
      AlertNotice('Complete all required fields', false)
      return
     }
      
    }

    const SchoolFeesData ={
      academicYear:selectedAcademicYear,
      academicTerm: selectedAcademicTerm,
      amount : parseFloat(amount),
      id :id,
      stage: stage,
      paymentMethod : paymentMethod,
      incomeType : incomeType,
      description : description
    }


    function handleSubmitSchoolFees(e) {
      e.preventDefault()
      //console.log(SchoolFeesData)
      
      if(SchoolFeesData.academicYear!==''&&SchoolFeesData.academicTerm!==''&&SchoolFeesData.amount!==''&&SchoolFeesData.id!==''&&SchoolFeesData.stage!==''&&SchoolFeesData.paymentMethod!==''&&SchoolFeesData.incomeType!==''){
        if(SchoolFeesData.amount<1){
          AlertNotice('Amount is less than 1', false)
            return
        }
        Progress('30%')
        // axios.post('https://new-softesc-backend.onrender.com/finance/income', IncomeData)
        axios.post('https://new-softesc-backend.onrender.com/finance/income', SchoolFeesData)
          .then((response) => {
            AlertNotice(response.data.message, true)
            Progress('100%')
            ResetInputs()
          })
          .catch((error) => {
            //console.log(error)
            AlertNotice('Failed to Add Income', false)
            Progress('0%')
          });
      }
      else{
        AlertNotice('Complete all required fields', false)
      }
    }


    function ResetInputs (){
      setSelectedAcademicTerm('')
      setSelectedAcademicYear('')
      setAmount(0)
      setId('')
      setStage('')
      setPaymentMethod('')
      setIncomeType('')
      setDescription('')
      setAllStudents([])


    }
      
    const fetchAcademicYear = async () => {
      try {
        Progress('30%');
        const academicYearResponse = await axios.get('https://new-softesc-backend.onrender.com/academicYear', {
         
        });
        setAcademicYear(academicYearResponse.data);
        Progress('100%');
      } catch (error) {
        console.error(error);
        Progress('0%');
      }
    };

    const fetchAcademicTerm = async () => {
      try {
        if(selectedAcademicYear !=='') {
          Progress('70%');
        const academicTermResponse = await axios.get('https://new-softesc-backend.onrender.com/academicTerm',{
          params:{
              academicYearName : selectedAcademicYear,
          }
        });
        setAcademicTerm(academicTermResponse.data);
        Progress('100%');
        }
        else{
          return
        }
      } catch (error) {
        console.error(error);
        Progress('0%');
      }
    };

    function fetchStudents (){
      if(!stage){
        return
      }
      Progress('30%')
      // axios.get('https://new-softesc-backend.onrender.com/students')
      axios.get('https://new-softesc-backend.onrender.com/students'
      ,{
        params:{
          stage : stage,
        }
      }
      )
        .then((response) => {
          Progress('100%')
          setAllStudents(response.data)
          // ////console.log(response.data)
      })
        .catch((error) => {
          Progress('0%')
          console.error(error)});
    }

    useEffect(()=>{
      ResetInputs()
    },[toggleIncomePallet])

    useEffect(()=>{
      fetchStudents()
    },[stage])

    useEffect(()=>{
      fetchAcademicYear()
    },[])

    useEffect(() => {
      fetchAcademicTerm();
    }, [selectedAcademicYear]);




  return (
    <div className="App">
        <SideMenu/>
        <Alert/>

        <div className="main-menu">
          <Header/>
          <div className='add-income content-loader'>
      <FinancialActions/>
      <form action="">
      <div className="add-income-body sorting">
      <div id="submit-reset-buttons">
        <button type='button' onClick={()=>{setToggleIncomePallet(true)}} style={toggleIncomePallet?{backgroundColor: '#f47c2c', color: '#031c3b' }:{backgroundColor: '#031c3b', color: 'white' }}>Add School Fees</button>
        <button type='button' onClick={()=>{setToggleIncomePallet(false)}} style={!toggleIncomePallet?{backgroundColor: '#f47c2c', color: '#031c3b' }:null}>Add Other Income</button>
      </div>
      <hr/>
       {
        toggleIncomePallet?
        <div style={{display : 'flex', flexDirection : 'column', gap:'1rem', position : 'relative'}} >

        {/* <h2>Add Student School Fees</h2> */}
        <div className="sorting-body">

          {/* academic year input  */}
        <div className="input">
          <label htmlFor="">Academic Year: </label>
          <select 
            name="" 
            id=""
            required
            value={selectedAcademicYear}
            onChange={(e)=>{setSelectedAcademicYear(e.target.value)}}
            >
            <option value="" disabled  hidden>select an academic year</option>
            
            {academicYear.map((year, index)=>(
                  <option key={index} value={year.academicYearName}>{year.academicYearName}</option>

            ))}            

          </select>
         
         
        </div>
          {/* academic term input  */}
        <div className="input">
          <label htmlFor="">Academic Term: </label>
          <select 
            name="" 
            required
            id=""
            value={selectedAcademicTerm}
            onChange={(e)=>{setSelectedAcademicTerm(e.target.value)}}
            >
            <option value="" disabled hidden>select an academic term</option>
            {academicTerm.map((term, index)=>(
                  <option key={index} value={term.refName}>{term.academicTermName}</option>

            ))}

          </select>
          
          
        </div>
          {/* type of income input  */}
        <div className="input">
          <label htmlFor="">Type of Income: </label>
          <select 
          id="mySelect" 
          required
          value={incomeType}
            onChange={(e)=>{setIncomeType(e.target.value)}}
          >
                  <option value="" disabled hidden>Select Type of Income</option>
                  <option value="schoolFees">School Fees</option>
                  <option value="uc-maths">UC MATHS</option>

              </select>
        </div>

          {/* student class input  */}
          <div className="input">
          <label htmlFor="">Class: </label>
          <select 
              id="mySelect"
              required
              value={stage}
              onChange={(e) => {setStage(e.target.value)}}                      
          >
              <option value='' disabled hidden>Select a Class </option>
              <option value={-3}>Preschool - Nursery One</option>
              <option value={-2}>Preschool - Nursery Two</option>
              <option value={-1}>Kindergarten One</option>
              <option value={0}>Kindergarten Two</option>
              <option value={1}>Class One</option>
              <option value={2}>Class Two</option>
              <option value={3}>Class Three</option>
              <option value={4}>Class Four</option>
              <option value={5}>Class Five</option>
              <option value={6}>Class Six</option>
              <option value={7}>Class Seven/ JHS 1</option>
              <option value={8}>Class Eight / JHS 2</option>
              <option value={9}>Class Nine / JHS 3</option>
          </select>
        </div>
        {/* }               */}

        {/* select student input  */}
        <div className="input">
        <label htmlFor="">Student: </label>
        <select 
          name="" 
          id=""
          required
          value={id}
          onChange={(e)=>{setId(e.target.value)}}
          >
          <option value="" hidden disabled>Select a student</option>
            {allStudents.length>0?allStudents.map((student, index)=>(
              <option key={index}  value={student.refNumber}>{student.fullName}</option>
            )):null}
        </select>
        </div>
        
        {/* amount input  */}
        <div className="input">
          <label htmlFor="">Amount: </label>
          <input 
            type="number" 
            min={0} 
            required
            placeholder='Enter the amount'
            value={amount}
            onChange={(e)=>{setAmount(e.target.value)}}
            />
        </div>

        {/* payment method input  */}
        <div className="input">
          <label htmlFor="">Payment Method: </label>
          <select 
          id="mySelect"
          required
          value={paymentMethod}
            onChange={(e)=>{setPaymentMethod(e.target.value)}}
          >
                  <option value="" disabled hidden>Cash/ Cheque/ Momo </option>
                  <option value="cash">Cash </option>
                  <option value="bank">Bank Transfer </option>
                  <option value="momo">Momo </option>
                  <option value="card">Credit Card </option>
                  <option value="others">Others </option>
                  
                  {/* <option value="option2">Transportation</option> */}
                  {/* <option value="option2">Transportation</option> */}
              </select> 
        </div>
        
       {/* description  */}
        <div className="input" style={{minWidth: '70%'}}>
          <label htmlFor="">Description: </label>
          <textarea 
            name="" 
            id="" 
            cols="30" 
            rows="10"
            value={description}
            onChange={(e)=>{setDescription(e.target.value)}}
            ></textarea>
        </div>
        </div>
        <div id="submit-reset-buttons">
          <button   onClick={handleSubmitSchoolFees}>Submit</button>
          <button type='button' onClick={ResetInputs}>Reset inputs </button>
        </div>
        </div>:
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        <div style={{display : 'flex', flexDirection : 'column', gap:'1rem'}}>
          
        {/* <h2>Add New Income</h2> */}
        <div className="sorting-body">

          {/* academic year input  */}
        <div className="input">
          <label htmlFor="">Academic Year: </label>
          <select 
            name="" 
            id=""
            required
            value={selectedAcademicYear}
            onChange={(e)=>{setSelectedAcademicYear(e.target.value)}}
            >
            <option value="" disabled  hidden>select an academic year</option>
            
            {academicYear.map((year, index)=>(
                  <option key={index} value={year.academicYearName}>{year.academicYearName}</option>

            ))}            

          </select>
         
         
        </div>
          {/* academic term input  */}
        <div className="input">
          <label htmlFor="">Term: </label>
          <select 
            name="" 
            required
            id=""
            value={selectedAcademicTerm}
            onChange={(e)=>{setSelectedAcademicTerm(e.target.value)}}
            >
            <option value="" disabled hidden>select an academic term</option>
            {academicTerm.map((term, index)=>(
                  <option key={index} value={term.refName}>{term.academicTermName}</option>

            ))}

          </select>
          
          
        </div>
          {/* type of income input  */}
        <div className="input">
          <label htmlFor="">Type of Income: </label>
          <select 
          id="mySelect" 
          required
          value={incomeType}
            onChange={(e)=>{setIncomeType(e.target.value)}}
          >
                  <option value="" hidden disabled>Choose Type of Income </option>
                  <option value="feedingFees">Feeding Fee</option>
                  <option value="booksAndStationary">Books and Stationery</option>
                  <option value="classesFees">Classes Fee</option>
                  <option value="examsFees">Exams Fees</option>
              </select>
        </div>

        {/* amount input  */}
        <div className="input">
          <label htmlFor="">Amount: </label>
          <input 
            type="number" 
            min={0} 
            required
            placeholder='Enter the amount'
            value={amount}
            onChange={(e)=>{setAmount(e.target.value)}}
            />
        </div>

        {/* payment method input  */}
        <div className="input">
          <label htmlFor="">Payment Method: </label>
          <select 
          id="mySelect"
          required
          value={paymentMethod}
            onChange={(e)=>{setPaymentMethod(e.target.value)}}
          >
                  <option value="" disabled hidden>Cash/ Cheque/ Momo </option>
                  <option value="cash">Cash </option>
                  <option value="bank">Bank Transfer </option>
                  <option value="momo">Momo </option>
                  <option value="card">Credit Card </option>
                  <option value="others">Others </option>
                  
                  {/* <option value="option2">Transportation</option> */}
                  {/* <option value="option2">Transportation</option> */}
              </select> 
        </div>
        
       {/* description  */}
        <div className="input" style={{minWidth: '70%'}}>
          <label htmlFor="">Description: </label>
          <textarea 
            name="" 
            required
            id="" 
            cols="30" 
            rows="10"
            value={description}
            onChange={(e)=>{setDescription(e.target.value)}}
            ></textarea>
        </div>
        </div>
        <div id="submit-reset-buttons">
        <button   onClick={handleSubmitIncome}>Submit</button>
        <button type='button' onClick={ResetInputs}>Reset inputs </button>

      </div>
      </div>
        
       }
       </div>
      
      </form><br />
      <hr />

      <form action="" onSubmit={handleSubmitIncome}>
      
      
      </form>
    </div>
        </div>
      </div>
    
  )
}

export default AddIncome