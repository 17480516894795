import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios'
import BPHeader from './TPCOMPONENTS/bp-header'
import BPSideMenu from './TPCOMPONENTS/bp-sidemenu'
import { StudentActions } from './TPCOMPONENTS/bp-reusables'
import { IoIosArrowDown } from 'react-icons/io';
import { UserInterfaceContext } from '../Contexts/ui-context';
import { Loading } from '../Components/reusables';
import { Alert } from '../Components/reusables';

function BPAttendance() {
    
  const [sortingView, setSortingView] = useState(false);
  const toggleSortingView = () => setSortingView(!sortingView);

  const { Progress, AlertNotice, setLoading } = useContext(UserInterfaceContext);
  const [studentData, setStudentData] = useState([]);
  const [stage, setStage] = useState('');
  const [attendanceDate, setAttendanceDate] = useState(new Date().toISOString().split('T')[0]);
  const [userId, setUserId] = useState('')
  const [isPresent, setIsPresent] = useState()
  const [academicTerm, setAcademicTerm] = useState([])
  const [selectedAcademicTerm, setSelectedAcademicTerm] = useState('')
  const [academicYear, setAcademicYear] = useState([])
  const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
  // const [attendanceCounter, setAttendanceCounter] = useState(0)
  const [attendanceList, setAttendanceList] = useState([])
  const [attendanceData, setAttendanceData] = useState([])
  const [toggleAttendancePallet, setToggleAttendancePallet] = useState('false')
  const [selectedAttendanceDate, SetSelectedAttendanceDate] = useState('')
  const [selectedStudent, setSelectedStudent] = useState('')
  const [attendanceStatus, setAttendanceStatus] = useState('')
 

  useEffect(()=>{
    // FetchAcademicYear()
    FetchAcademicTerm();
  },[selectedAcademicYear])

  function formatDateToDayAndDate(isoDate) {
    const date = new Date(isoDate);
    const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    return `${dayName}, ${formattedDate}`;
}



  useEffect(() => {

    if (stage !== '') {
      Progress('30%');
      setLoading(true)
      axios
        .get('https://new-softesc-backend.onrender.com/students', {
          params: { stage: stage },
        })
        .then((response) => {
          Progress('100%');
          // ////console.log(response.data);
          setStudentData(response.data);
          setLoading(false)
        })
        .catch((error) => {
          Progress('0%');
          //console.log(error);
        });
    }
  }, [stage]);

  
  function FetchAcademicYear(){
    Progress('30%')
    axios.get('https://new-softesc-backend.onrender.com/academicYear',)
    .then((response)=>{
      setAcademicYear(response.data)
      Progress('100%')
      setSelectedAcademicTerm('')
    })
    .catch((error)=>{
      //console.log(error)
      Progress('100%')
    })
  }

  function FetchAcademicTerm (){
    Progress('30%')
    axios.get('https://new-softesc-backend.onrender.com/academicTerm',{
        params:{
            academicYearName : selectedAcademicYear===''?'none':selectedAcademicYear,
        }
      })
      .then((response)=>{
        setAcademicTerm(response.data)
        Progress('100%')

      })
      .catch((error)=>{
        Progress('0%')

      })
  }



  useEffect(() => {
    if (stage !== '') {
      Progress('30%');
      setLoading(true)
      if(!selectedAcademicTerm || !selectedAcademicYear) {
        setLoading(false)
        return
      }

      axios
        .get('https://new-softesc-backend.onrender.com/attendance', {
          params: { academicYear : selectedAcademicYear,
                    academicTerm : selectedAcademicTerm,
                    stage: stage,
                    student : selectedStudent,
                    date : selectedAttendanceDate,
                    isPresent: attendanceStatus,

           },
        })
        .then((response) => {
          setLoading(false)
          Progress('100%');
          // console.log(response.data);
          setAttendanceData(response.data);
        })
        .catch((error) => {
          Progress('0%');
          //console.log(error);
        });
    }
  }, [selectedAcademicTerm, selectedAcademicYear, stage, selectedStudent, selectedAttendanceDate, attendanceStatus]);




  function HandleSubmitAttendance(attendanceData){
    setAttendanceData(attendanceData)
    if(!attendanceDate){
      AlertNotice('Add Attendance Date', false)
      return
    }
    Progress('30%')
    axios.post('https://new-softesc-backend.onrender.com/attendance', attendanceData)
      .then((response) => {
        Progress('100%')
        AlertNotice(response.data.message, true)
        UpdateAttendanceList()
      })
      .catch((error) => {
        Progress('0%')
        //console.log(error);
        AlertNotice(error.response.data.error, false)
      });
  };

function UpdateAttendanceList (){
  if (!attendanceList.includes(attendanceData.userId)) {
    setAttendanceList(attendanceList + userId + ' ');
}
}

  function CheckAttendance (a, b){
    setUserId(a)
    setIsPresent(b)
    const attendanceData = {
      stage: stage,
      userId: a,
      attendanceDate: attendanceDate,
      isPresent: b,
    };

    HandleSubmitAttendance(attendanceData)
    
}




  useEffect((userId, isPresent)=>{
    if(userId&&isPresent){
      CheckAttendance(userId, isPresent)
    }
    else{
      // //console.log('some required values are not in the attendance data')
    }
   },[userId,isPresent])


//    useEffect(()=>{
//     //console.log(attendanceList)
// },[attendanceList])

// useEffect(()=>{
//     //console.log(attendanceCounter)
// },[attendanceCounter])


  return (
    <div className='App'>
      <BPSideMenu/>
      <Alert/>
      <div className='main-menu'>
        <BPHeader/>
        <div className="attendance content-loader">
          <StudentActions />
          { !toggleAttendancePallet? <div>
            <div className="sorting">

            <div id="submit-reset-buttons">
              <button type='button' onClick={()=>{setToggleAttendancePallet(true)}} style={toggleAttendancePallet?{backgroundColor: '#f47c2c', color: '#031c3b' }:{backgroundColor: '#031c3b', color: 'white' }}>All Attendance</button>
              <button type='button' disabled onClick={()=>{setToggleAttendancePallet(false)}} style={!toggleAttendancePallet?{backgroundColor: '#f47c2c', color: '#031c3b' }:null}>Add Attendance</button>
            </div>
            <hr />

            <div className="sorting-header" onClick={toggleSortingView}>
              <h2>Select Class and Date</h2>
              <IoIosArrowDown id="sorting-down-arrow" />
            </div>
            <div className="sorting-body" style={{ display: sortingView ? 'none' : 'flex' }}>
              <div className="input">
                <label htmlFor="">Class: </label>
                <select
                  id="mySelect"
                  required
                  value={stage}
                  onChange={(e) => {setStage(e.target.value)}                }>
                        <option value='' disabled hidden>Select a class eg. class 0</option>
                        <option value={-3}>Preschool - Nursery One</option>
                        <option value={-2}>Preschool - Nursery Two</option>
                        <option value={-1}>Kindergarten One</option>
                        <option value={0}>Kindergarten Two</option>
                        <option value={1}>Class One</option>
                        <option value={2}>Class Two</option>
                        <option value={3}>Class Three</option>
                        <option value={4}>Class Four</option>
                        <option value={5}>Class Five</option>
                        <option value={6}>Class Six</option>
                        <option value={7}>Class Seven/ JHS 1</option>
                        <option value={8}>Class Eight / JHS 2</option>
                        <option value={9}>Class Nine / JHS 3</option>
                </select>
              </div>

              <div className="input">
                <label htmlFor="">Date:</label>
                <input type="date" 
                value={attendanceDate} 
                onChange={(e) => setAttendanceDate(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="container">
            <table className="user-table">
              <Loading/>
              <thead>
                <tr>
                  <th>Roll</th>
                  <th>Student Id</th>
                  <th id="user-name-head">Name</th>
                  <th>Mark Attendance</th>
                </tr>
              </thead>
              <tbody>
                {studentData.length > 0 ? (
                  studentData.map((student, index) => (
                    <tr id="tr" key={index}>
                      <td>{index + 1}</td>
                      <td>{student.refNumber}</td>
                      <td id="user-name" style={attendanceList.includes(student.refNumber)?{ color: 'yellow'}:{color :'white'}}>{student.firstName + ' ' + student.middleName + ' ' + student.lastName}</td>
                      <td id="attendance-checkbox">
                        <button id='present' style={{backgroundColor: 'green'}} onClick={()=>{CheckAttendance(student.refNumber, true)}}>Present</button>
                        <button id='absent' style={{backgroundColor: 'red'}} onClick={()=>{CheckAttendance(student.refNumber, false)}}>Absent</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" style={{padding:'1rem', color:'red'}}>No students available in selected Class or You have not selected a class</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          
          </div>:null}
          {
            toggleAttendancePallet?
            <div className='sorting'>
              <div id="submit-reset-buttons">
                <button type='button' onClick={()=>{setToggleAttendancePallet(true)}} style={toggleAttendancePallet?{backgroundColor: '#f47c2c', color: '#031c3b' }:{backgroundColor: '#031c3b', color: 'white' }}>All Attendance</button>
                <button type='button' disabled onClick={()=>{setToggleAttendancePallet(false)}} style={!toggleAttendancePallet?{backgroundColor: '#f47c2c', color: '#031c3b' }:null}>Add Attendance</button>
              </div>
              <hr />
              <div className="sorting-body">
              <div className="input">
                <label htmlFor="">Academic Year: </label>
                <select 
                  name="" 
                  id=""
                  value={selectedAcademicYear}
                  onChange={(e)=>{setSelectedAcademicYear(e.target.value)}}

                  >
                  {/* <option value="" disabled hidden >select an academic year</option> */}
                  <option value="" >All</option>
                  {academicYear.map((year, index)=>(
                        <option key={index} value={year.academicYearName}>{year.academicYearName}</option>

                  ))}            

                </select>
                

              </div>
              <div className="input">
                <label htmlFor="">Academic Term: </label>
                <select 
                  name="" 
                  id=""
                  value={selectedAcademicTerm}
                  disabled = {selectedAcademicYear?false :true}
                  onChange={(e)=>{setSelectedAcademicTerm(e.target.value)}}
                  >
                  <option value="" disabled hidden>select an academic term</option>
                  {academicTerm.map((term, index)=>(
                        <option key={index} value={term.academicTermName}>{term.academicTermName}</option>

                  ))}
                </select>
                
                
              </div>
                    <div className="input">
                      <label htmlFor="">Class: </label>
                      <select
                        id="mySelect"
                        required
                        value={stage}
                        onChange={(e) => {setStage(e.target.value)}                }>
                              <option value='' disabled hidden>Select a class eg. class 0</option>
                              <option value={-3}>Preschool - Nursery One</option>
                              <option value={-2}>Preschool - Nursery Two</option>
                              <option value={-1}>Kindergarten One</option>
                              <option value={0}>Kindergarten Two</option>
                              <option value={1}>Class One</option>
                              <option value={2}>Class Two</option>
                              <option value={3}>Class Three</option>
                              <option value={4}>Class Four</option>
                              <option value={5}>Class Five</option>
                              <option value={6}>Class Six</option>
                              <option value={7}>Class Seven/ JHS 1</option>
                              <option value={8}>Class Eight / JHS 2</option>
                              <option value={9}>Class Nine / JHS 3</option>
                      </select>
                    </div>
                    <div className="input ">
                              <label htmlFor="">Student</label>
                              <select name="" id=""
                                  value={selectedStudent} 
                                  required
                                  disabled= {stage?false:true}
                                  onChange={(e)=>{setSelectedStudent(e.target.value)}}
                              >
                                  <option value="" disabled hidden>Choose a Student</option>
                                  {studentData.map((student, index)=>(
                                      <option  key={index} value={student.refNumber}>{student.firstName +' '+ student.middleName +' ' +  student.lastName}</option>

                                  ))}                        
                              </select>
                          </div>
                    <div className="input">
                      <label htmlFor="">Date:</label>
                      <input type="date" 
                      value={selectedAttendanceDate} 
                      onChange={(e) => SetSelectedAttendanceDate(e.target.value)} />
                    </div>

                    <div className="input">
                      <label htmlFor="">Status: </label>
                      <select
                        id="mySelect"
                        required
                        value={attendanceStatus}
                        onChange={(e) => {setAttendanceStatus(e.target.value)}                }>
                              <option value='' disabled hidden>Attendance Status</option>
                              <option value={true}>Present</option>
                              <option value={false}>Absent</option>
                              
                      </select>
                    </div>
                    </div>
                    <hr />
              <div className="userfeature">
              <b>Total Fetched : {attendanceData.length}</b>
            </div>
              <div className="container">
          <table className='user-table'>
            <Loading/>
              <thead>
                <tr>
                  <th> Count  </th>
                  <th>Ref Number</th>
                  <th id='user-name-head'>Days | Dates</th>
                  {/* <th>Amount  <br />  (GHS {TotalIncome()})</th> */}
                  {/* <th>Phone / Email</th> */}
                  {/* <th>Description</th> */}
                  <th>Status</th>
                  {/* <th id='user-actions-head'>Action</th> */}
                </tr>
              </thead>
              <tbody>

                {
                  attendanceData.map((attendance, index)=>(
                    <tr key={index}>
                      <td>{index +1}</td>
                      <td>{attendance.student}</td>
                      <td>{formatDateToDayAndDate(attendance.attendanceDate)}</td>
                      <td id='no-wrap'>{attendance.isPresent?'Present':'Absent'}</td>
                      {/* <td id='table-actions'>
                      <FaEdit/>
                       
                          </td> */}
                  </tr>
                  ))

                }
               
                
                
              </tbody>
        </table>
      </div>

            </div>:null
          }
          
        </div>

      </div>
    </div>
  )
}

export default BPAttendance