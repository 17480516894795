import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import SideMenu from './TPCOMPONENTS/tp-sidemenu'
import { UserInterfaceContext } from '../Contexts/ui-context'
import { IoIosArrowDown } from 'react-icons/io'
import Header from './TPCOMPONENTS/tp-header'
import TPHeader from './TPCOMPONENTS/tp-header';

import { UserAuthenticationContext } from '../Contexts/AuthenticationContext'
function TeachersDashboard() {
  const {CurrentUserData}= useContext(UserAuthenticationContext)
  const {Progress, setPageName} = useContext(UserInterfaceContext)
  const [dashBoardData, setDashboardData] = useState([])
  const [pastMessages, setPastMessages] = useState([])
  const [stage, setStage] = useState(CurrentUserData?.stage)

  setPageName('DASHBOARD')
  useEffect(()=>{
    //console.log(CurrentUserData?.stage)
    axios.get('https://new-softesc-backend.onrender.com/dashboard' ,{
      params: {
        stage: stage
      }
    })
    .then((response)=>{
      setDashboardData(response.data.teacherDashBoard)
      ////console.log(response.data)
      
    })
    .catch((error)=>{
      //console.log(error)
    })
    GetNotices();
  },[])



  async function GetNotices (){
    Progress('70%')
   await axios.get('https://new-softesc-backend.onrender.com/notices',
   {
    params: {
      receiver : 'Staff'
    }
   }
   )
    .then((response)=>{
      Progress('100%')
      setPastMessages(response.data)
      ////console.log(response)
    })
    .catch((error)=>{
      Progress('0%')
      //console.log('unable to retrieve past messages', error)
    })
  }

  const [showRecent, setShowRecent] = useState(false)
  function ToggleRecent (){
    setShowRecent(!showRecent)
    setShowNotice(!showNotice)
  }
  const [showNotice, setShowNotice] = useState(true)
  function ToggleNotice (){
    setShowNotice(!showNotice)
    setShowRecent(!showRecent)
  }

  function ToTwoDecimals (amount){
    return(Math.round(amount*100)/100)
  }
  return (
    <div className="App">
        <SideMenu/>
        {/* <ProcessingPage/> */}

        <div className="main-menu">
          <TPHeader/>
          <div className='main-dash-board content-loader'>
          {/* <SuperActionButtons/> */}

            <div className="dash-pane" >
           
                <div className="container">
                {
              [dashBoardData].map((dashData, index)=>(

                  <>
                  <h1>STUDENTS </h1>
                  <div className="dashboard-summary" key={index}>
                   
                    <NavLink to ='/TPStudents'className='summary-item'>
                      <div id="item-header">
                        <h3>STUDENTS</h3>
                        <hr />

                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>{dashData.numberOfStageStudents?dashData.numberOfStageStudents:'00'}</h3>
                      </div>
                        <div id="item-description">
                          <b>Male: {dashData.numMaleStageStd?dashData.numMaleStageStd: '00'}</b>
                          <b>Female: {dashData.numFemaleStageStd?dashData.numFemaleStageStd: '00'}</b>
                        </div>
                    </div>
                    </NavLink>
                    <NavLink to ='/TPAttendance'className='summary-item'>
                      <div id="item-header">
                        <h3>STD - ATTENDANCE</h3>
                        <hr />

                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>{dashData.stageStdAttendance?dashData.stageStdAttendance:'00'}</h3>
                      </div>
                        {/* <div id="item-description">
                          <b>Male: 0</b>
                          <b>Female: 0</b>
                        </div> */}
                    </div>
                    </NavLink>
                    
                    

                  
                  </div>
                  </>

    ))
    }



             
                </div>
            

                <div className="notice-recent-activities" style={{Height: (showRecent&&showNotice)?'auto': '6rem'}}>

                <div id="notice-board-header" onClick={()=>{ToggleNotice()}} style={{color:showNotice?'var(--primary-color-yellow)':'white', marginBottom : showNotice? '1rem': '2rem'}}>
                    <h2>Notice Board</h2>
                    <IoIosArrowDown style={{transform: showNotice?'rotate(180deg)': 'rotate(0deg)'}}/>
                  </div>
                <div className="noticeboard" style={{maxHeight: showNotice?'100%':'0rem'}}>
                    

                    <div className="notice-body">
                    {pastMessages.length>0?(pastMessages.map((pastMessages, index)=>(
                            <div className="message" key={index}>
                            {/* <p id='message-body' style={{color: 'green'}}> <b>Reciever</b> : {pastMessages.reciever}  </p> */}
                            <p id='message-body'> <b>Message title </b>: {pastMessages.title} </p>
                            <p id='message-body'> <b>Date Sent</b> : {pastMessages.dateAdded} </p>
                            <p id='message-body'><b >Message</b> : {pastMessages.messageBody}</p>
                            <div className="itemcounter">{index+1}</div>
                          </div>
                        ))):(
                          <p style={{color: 'red', padding: '1rem'}}>No messages Yet</p>
                        )}

                    </div>
                  </div>
                  
                  <div id="recent-activities-header" onClick={()=>{ToggleRecent()}} style={{top: showNotice?'-1rem':'1rem', color:showRecent?'var(--primary-color-yellow)':'white', marginTop : showRecent?'3rem': '3rem', marginTop : showNotice?'0.5rem': '3rem'}}>
                      <h2>Recent activities</h2>
                      <IoIosArrowDown style={{transform: showRecent?'rotate(180deg)': 'rotate(0deg)'}}/>
                      </div>
                  <div className="recent-activities" style={{maxHeight: showRecent?'100%':'0rem', top: showNotice?'0px':'2.5rem'}}>
                      
                      
                      <div className="activities-body">
                            <div className="activity">
                                <label htmlFor="">By : </label><br />
                                <label htmlFor="">Activity:</label><br />
                                <label htmlFor="">State:</label><br />
                                <label htmlFor="">Date:</label><br />
                                <div className="itemcounter">{1}</div>

                            </div>
                            <div className="activity">
                                <label htmlFor="">By : </label><br />
                                <label htmlFor="">Activity:</label><br />
                                <label htmlFor="">State:</label><br />
                                <label htmlFor="">Date:</label><br />
                                <div className="itemcounter">{1}</div>

                            </div>
                                                                                                                                                                                                                                                                                                                                                                                           
                      </div>
                      
                      
                  </div>
                  
                </div>
        </div>
       
    </div>
        </div>
      </div>
    



  )
}

export default TeachersDashboard