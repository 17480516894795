import React from 'react'
import axios from 'axios'
import TPHeader from './TPCOMPONENTS/tp-header'
import TPSideMenu from './TPCOMPONENTS/tp-sidemenu'
import { UserInterfaceContext } from '../Contexts/ui-context'
import { useContext,useState, useEffect } from 'react'
import { Loading } from '../Components/reusables'

function TPMessages() {
  const {setPageName, Progress, AlertNotice} = useContext(UserInterfaceContext)
  setPageName('NOTICES AND MESSAGES')
  const [sortingView, setSortingView] = useState(false)
  function toggleSortingView (){
    setSortingView(!sortingView)
  }

  const [pastMessages, setPastMessages] = useState([])
  const [reciever, setReciever] = useState('')
  const [sender, setSender] = useState('Admin')
  const [title, setTitle] = useState('')
  const [channel, setChannel] = useState('')
  const [messageBody, setMessageBody] = useState()
  const [pastMessageReload, setPastMessageReload] = useState(false)

  function formatDateToDayAndDate(isoDate) {
    const date = new Date(isoDate);
    const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    return `${dayName}, ${formattedDate}`;
}

  //call for the past messages

  useEffect(()=>{
    Progress('30%')
     // axios.get('https://new-softesc-backend.onrender.com/notices'
    axios.get('https://new-softesc-backend.onrender.com/notices', {
      params :{
        reciever: 'Staff'
      }
    })
    .then((response)=>{
      Progress('100%')
      setPastMessages(response.data)
      ////console.log(response)
    })
    .catch((error)=>{
      Progress('0%')
      //console.log('unable to retrieve past messages', error)
    })

  },[pastMessageReload])


  return (
    <div className='App'>
      <TPSideMenu/>
      <div className='main-menu'>
        <TPHeader/>
        <div  className='content-loader'>
            {/* the main menu content goes here  */}
            <div className="past-messages">
            <div className="container">
                  <table>
                    <Loading/>
                  <thead>
                    <tr>
                      {/* <th id='serial-num-head'>Message ID</th> */}
                      {/* <th>Sender</th> */}
                      <th id='user-name-head'>Reciever</th>
                      <th>Title</th>
                      <th>Message </th>
                      <th>dateAdded</th>
                      {/* <th>Channel</th> */}
                      {/* <th>Status</th>  */}
                      {/* <th>Credit Cost</th>  */}
                      {/* <th id='user-actions-head'>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {pastMessages.length>0?(pastMessages.map((pastMessages, index)=>(
                        <tr key={index}>
                        {/* <td>{pastMessages._id}</td> */}
                        {/* <td>{pastMessages.sender}</td> */}
                        <td>{pastMessages.receiver.toUpperCase()}</td>
                        <td>{pastMessages.title}</td>
                        <td>{pastMessages.messageBody}</td>
                        <td>{formatDateToDayAndDate(pastMessages.dateAdded)}</td>
                        {/* <td>{pastMessages.channel}</td> */}
                        {/* <td>null</td> */}
                        {/* <td>null</td> */}
                        {/* <td id='table-actions'>
                          <div>
                            <FaTrash id='action-delete' onClick={()=>{    }}/>
                          </div>
                            </td> */}
                      </tr>
                    ))):
                    (<tr>
                      <td colSpan='22' style={{padding:'1rem', color:'red'}}>NO MESSAGES YET</td>
                    </tr>)
                    }
                  </tbody>
                </table>
                  </div>

                
                                
              </div>
        </div>

      </div>
    </div>
  )
}

export default TPMessages