import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
// import SideMenu from '../../Components/sideMenu'
import Header from '../../Components/header'
import { SuperActionButtons } from '../../Components/reusables'
import { UserInterfaceContext } from '../../Contexts/ui-context'
import { IoIosArrowDown } from 'react-icons/io';
import { SideMenu } from '../../Components/sideMenu'
import { Loading } from '../../Components/reusables'


function MainDashBoard() {
  const {Progress, loading, setLoading} = useContext(UserInterfaceContext)
  const [dashBoardData, setDashboardData] = useState([])
  const [pastMessages, setPastMessages] = useState([])
  const [financialData, setFinancialData] = useState([])
  const [inventoryData, setInventoryData] = useState({})
  

  function formatDateToDayAndDate(isoDate) {
    const date = new Date(isoDate);
    const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    return `${dayName}, ${formattedDate}`;
}


  useEffect(()=>{
    setLoading(true)
    // axios.get('https://new-softesc-backend.onrender.com/dashboard'
    axios.get('https://new-softesc-backend.onrender.com/dashboard'
    ,{
    })
    .then((response)=>{
      setDashboardData(response.data.dashboard)
      //console.log(response.data.dashboard)
      setLoading(false)
      
    })
    .catch((error)=>{
      //console.log(error)
    })
    GetfinancialData();
    GetNotices();
    // GetInventoryData()
// //console.log( new Date().toISOString().split('T')[0])
  },[])


// async function GetInventoryData (){
      // axios.get('https://new-softesc-backend.onrender.com/inventory'

//   axios.get('https://new-softesc-backend.onrender.com/inventory')
//     .then((response)=>{
//       ////console.log(response)
//     })
//     .catch((error)=> {
//       //console.log(error)
//     })
// }




  async function GetfinancialData (){
    // axios.get('https://new-softesc-backend.onrender.com/dashboardData'

    axios.get('https://new-softesc-backend.onrender.com/finance/dashboardData'
    )
    .then((response)=>{
      // ////console.log(response.data)
      setFinancialData(response.data)
    })
    .catch((error)=> {
      //console.log(error)
    })
  }

  async function GetNotices (){
    Progress('70%')
    // axios.get('https://new-softesc-backend.onrender.com/notices'
   await axios.get('https://new-softesc-backend.onrender.com/notices')
    .then((response)=>{
      Progress('100%')
      setPastMessages(response.data)
      // ////console.log(response)
    })
    .catch((error)=>{
      Progress('0%')
      //console.log('unable to retrieve past messages', error)
    })
  }

  const [showRecent, setShowRecent] = useState(false)
  function ToggleRecent (){
    setShowRecent(!showRecent)
    setShowNotice(!showNotice)
  }
  const [showNotice, setShowNotice] = useState(true)
  function ToggleNotice (){
    setShowNotice(!showNotice)
    setShowRecent(!showRecent)
  }

  function ToTwoDecimals (amount){
    return(Math.round(amount*100)/100)
  }


  return (
    <div className="App">
        <SideMenu/>
        {/* <ProcessingPage/> */}

        <div className="main-menu">
          <Header/>
          <div className='main-dash-board content-loader'>
          <SuperActionButtons/>

            <div className="dash-pane" >
           
                <div className="container">
                {
              [dashBoardData].map((dashData, index)=>(

                  <>
                  <h1>STAFF AND STUDENTS </h1>
                  <div className="dashboard-summary" key={index}>
                    <NavLink to ='/Allteachers'className='summary-item'>
                      <div id="item-header">
                        <h3>STAFF</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>{dashData.numberOfTeachers?dashData.numberOfTeachers:'00'}</h3>
                      </div>
                        <div id="item-description">
                          <b>Male: {dashData.numMaleStf?dashData.numMaleStf: '00'}</b>
                          <b>Female: {dashData.numFemaleStf?dashData.numFemaleStf: '00'}</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>
                    {/* <NavLink to ='/Attendance'className='summary-item'>
                      <div id="item-header">
                        <h3> STF - ATTENDANCE</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>{dashData.stdAttendance?dashData.stdAttendance:'00'}</h3>
                      </div>
                        <div id="item-description">
                          <b>Male: 0</b>
                          <b>Female: 0</b>
                        </div>
                    </div>
                    </NavLink> */}
                    <NavLink to ='/AllStudent'className='summary-item'>
                      <div id="item-header">
                        <h3>STUDENTS</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>{dashData.numberOfStudents?dashData.numberOfStudents:'00'}</h3>
                      </div>
                        <div id="item-description">
                          <b>Male: {dashData.numMaleStd?dashData.numMaleStd: '00'}</b>
                          <b>Female: {dashData.numFemaleStd?dashData.numFemaleStd: '00'}</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>
                    <NavLink to ='/Attendance'className='summary-item'>
                      <div id="item-header">
                        <h3>STD - ATTENDANCE</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>{dashData.stdAttendance?dashData.stdAttendance:'00'}</h3>
                      </div>
                        <div id="item-description">
                          <b>Male: 0</b>
                          <b>Female: 0</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>
                    
                    

                  
                  </div>
                  </>

    ))
    }



                  <h1>FINANCE AND ACCOUNTS </h1>
                  {
                    [financialData].map((financialData, index)=>(
                      <div className="dashboard-summary">
                    
                    <NavLink to ='/AllIncome'className='summary-item'>
                      <div id="item-header">
                        <h3>INCOME (GHS)</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>{financialData.totalIncome?(ToTwoDecimals(financialData.totalIncome)):'00'}</h3>
                      </div>
                        <div id="item-description">
                          <b>TODAY : {financialData.totalIncomeToday?financialData.totalIncomeToday:'00'}</b>
                          <b>MONTH : {financialData.totalIncomeMonth?financialData.totalIncomeMonth:'00'}</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>
                    
                    <NavLink to ='/AllExpenses'className='summary-item'>
                      <div id="item-header">
                        <h3>EXPENSE (GHS)</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>{financialData.totalExpense?(ToTwoDecimals(financialData.totalExpense)):'00'}</h3>
                      </div>
                        <div id="item-description">
                        <b>TODAY : {financialData.totalExpenseToday?financialData.totalExpenseToday:'00'}</b>
                          <b>MONTH : {financialData.totalExpenseMonth?financialData.totalExpenseMonth:'00'}</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>

                    <NavLink to ='/FeeCollection'className='summary-item'>
                      <div id="item-header">
                        <h3>ACC BALANCE (GHS)</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        {/* <h3>{(financialData.totalIncome&&financialData.totalExpense)?(ToTwoDecimals(financialData.totalIncome-financialData.totalExpense)):'00'}</h3> */}
                        <h3>
                          {
                            ToTwoDecimals(
                              (financialData.totalIncome ? financialData.totalIncome : 0) -
                              (financialData.totalExpense ? financialData.totalExpense : 0)
                            ) 
                            ? ToTwoDecimals(
                              (financialData.totalIncome ? financialData.totalIncome : 0) -
                              (financialData.totalExpense ? financialData.totalExpense : 0)
                            )
                            : '00'
                          }
                        </h3>                      </div>
                        <div id="item-description">
                          <b>TODAY: {(ToTwoDecimals(financialData.totalIncomeToday-financialData.totalExpenseToday))}</b>
                          <b>MONTH: {(ToTwoDecimals(financialData.totalIncomeMonth-financialData.totalExpenseMonth))}</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>
                    <NavLink to ='/FeeCollection'className='summary-item'>
                      <div id="item-header">
                        <h3>SCH FEES  (GHS)</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>{(financialData.totalIncome&&financialData.totalExpense)?(ToTwoDecimals(financialData.totalIncome-financialData.totalExpense)):'00'}</h3>
                      </div>
                        <div id="item-description">
                          <b>PAID: 0</b>
                          <b>STD-DEBT: 0</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>
                  
                  </div>
                    ))
                  }
                  

                  <h1>STOCK AND INVENTORY </h1>
                  <div className="dashboard-summary">
                    
                    
                    <NavLink className='summary-item'>
                      <div id="item-header">
                        <h3>COMPUTERS</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>00</h3>
                      </div>
                        <div id="item-description">
                          <b>Active: 0</b>
                          <b>Faulty: 0</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>
                    <NavLink className='summary-item'>
                      <div id="item-header">
                        <h3>SCH-UNIFORMS</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>00</h3>
                      </div>
                        <div id="item-description">
                          <b>Male: 0</b>
                          <b>Female: 0</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>
                    <NavLink className='summary-item'>
                      <div id="item-header">
                        <h3>BOOKS</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>00</h3>
                      </div>
                      <div id="item-description">
                          <b>Text Books: 0</b>
                          <b>Note Books: 0</b>
                          <b>Exercise Books: 0</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>
                    <NavLink className='summary-item'>
                      <div id="item-header">
                        <h3>FURNITURE</h3>
                        <hr />
                      </div>
                    <div id="item-body">
                      <div id="value">
                        <h3>00</h3>
                      </div>
                      <div id="item-description">
                          <b>Good State  : 0</b>
                          <b>Needs Repairs : 0</b>
                        </div>
                    </div>
                                    <Loading/>

                    </NavLink>
                    
                  </div>
                  
                  
                </div>
            

                <div className="notice-recent-activities" style={{Height: (showRecent&&showNotice)?'auto': '6rem'}}>

                <div id="notice-board-header" onClick={()=>{ToggleNotice()}} style={{color:showNotice?'var(--primary-color-yellow)':'white', marginBottom : showNotice? '1rem': '2rem'}}>
                    <h2>Notice Board</h2>
                    <IoIosArrowDown style={{transform: showNotice?'rotate(180deg)': 'rotate(0deg)'}}/>
                  </div>
                <div className="noticeboard" style={{maxHeight: showNotice?'100%':'0rem'}}>
                    

                    <div className="notice-body">
                    {pastMessages.length>0?(pastMessages.map((pastMessages, index)=>(
                            <div className="message" key={index}>
                            <p id='message-body'> <b>Reciever</b> : {pastMessages.reciever}  </p>
                            <p id='message-body'> <b>Message title </b>: {pastMessages.title} </p>
                            <p id='message-body'> <b>Date Sent</b> : {pastMessages.date} </p>
                            <p id='message-body'><b >Message</b> : {pastMessages.messageBody}</p>
                            <div className="itemcounter">{index+1}</div>
                          </div>
                        ))):(
                          <p style={{color: 'red', padding: '1rem'}}>No messages Yet</p>
                        )}

                    
                    </div>
                  </div>
                  
                  <div id="recent-activities-header" onClick={()=>{ToggleRecent()}} style={{top: showNotice?'-1rem':'1rem', color:showRecent?'var(--primary-color-yellow)':'white', marginTop : showRecent?'3rem': '3rem', marginTop : showNotice?'0.5rem': '3rem'}}>
                      <h2>Recent activities</h2>
                      <IoIosArrowDown style={{transform: showRecent?'rotate(180deg)': 'rotate(0deg)'}}/>
                      </div>
                  <div className="recent-activities" style={{maxHeight: showRecent?'100%':'0rem', top: showNotice?'0px':'2.5rem'}}>
                      
                      
                      <div className="activities-body">
                            <div className="activity">
                                <label htmlFor="">By : </label><br />
                                <label htmlFor="">Activity:</label><br />
                                <label htmlFor="">State:</label><br />
                                <label htmlFor="">Date:</label><br />
                                <div className="itemcounter">{1}</div>

                            </div>
                          
                      </div>
                      
                      
                  </div>
                  
                </div>
        </div>
       
    </div>
        </div>
      </div>
    



  )
}

export default MainDashBoard