import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import SideMenu from './SPCOMPONENTS/sp-sidemenu'
import { IoIosArrowDown } from 'react-icons/io'
import Header from './SPCOMPONENTS/sp-header'
import { Navigate } from 'react-router-dom';
import { FaUserGraduate, FaUser,FaUsers,} from 'react-icons/fa';
import { UserInterfaceContext } from '../Contexts/ui-context'
import { UserAuthenticationContext } from '../Contexts/AuthenticationContext'

function StudentDashboard() {
  const {CurrentUserData} = useContext(UserAuthenticationContext)
  const {Progress, setPageName} = useContext(UserInterfaceContext)
  setPageName('MY INFO')
  const {userId, } = useState(CurrentUserData?.refNumber)
 
  if(userId===''){
    return <Navigate to='/AllStudent'/>
  }
  function GenerateStageName (stage){
    if(stage===-4){
        return('PRE-SCHOOL-CRECHE')
    }
    else if(stage===-3){
        return('NURSERY 1')
    }
    else if(stage===-2){
        return('NURSERY 2')
    }
    else if(stage===-1){
        return('KINDERGARTEN 1')
    }
    else if(stage===0){
        return('KINDERGARTEN 2')
    }
    else if(stage===1){
        return('CLASS 1')
    }
    else if(stage===2){
        return('CLASS 2')
    }
    else if(stage===3){
        return('CLASS 3')
    }
    else if(stage===4){
        return('CLASS 4')
    }
    else if(stage===5){
        return('CLASS 5')
    }
    else if(stage===6){
        return('CLASS 6')
    }
    else if(stage===7){
        return('CLASS 7 / JHS 1')
    }
    else if(stage===8){
        return('CLASS 8 / JHS 2')
    }
    else if(stage===9){
        return('CLASS 9 / JHS 3')
    }
  }

  return (
    <div className="App">
        <SideMenu/>

        <div className="main-menu">
          <Header/>
          <div className='main-dash-board content-loader'>
          {/* <h3 className='class-level'>STUDENT DETAILS : <b style={{color: 'yellow'}}>{userId}</b></h3> */}
      <div>
            <div className="user-header">
                <div className="overlay">
                </div>
                <div className="img-container">
              <FaUserGraduate id='user-details-icon'/>
            </div>
            <div className="personal-details">
              <label htmlFor="">Student ID : {CurrentUserData?.refNumber} </label>
              <label htmlFor="">Name : {CurrentUserData?.fullName} </label>
              <label htmlFor="">{GenerateStageName(CurrentUserData?.stage)} </label>
            </div>
            </div>

          <div className='info'>
              <div  className="personal-info">
                  <h3>PERSONAL INFORMATION</h3>
                  <label htmlFor="">Gender : {CurrentUserData?.gender} </label>
                  <label htmlFor="">Class Teacher : {CurrentUserData?.classTeacher} </label>
                  {/* <label htmlFor="">School Fees : </label> <label htmlFor="">Paid 300.00 out of 450.00</label> */}
                  <label htmlFor="">House : {CurrentUserData?.house} </label>
                  <label htmlFor="">Address : {CurrentUserData?.presentAddress} </label>
                  <label htmlFor="">Date of Birth : {CurrentUserData?.dob} </label>
                  <label htmlFor="">Father's Name : {CurrentUserData?.fatherName} </label>
                  <label htmlFor="">Father's Contact  : {CurrentUserData?.fatherPhone}  </label>
                  <label htmlFor="">Mother's Name : {CurrentUserData?.motherName} </label>
                  <label htmlFor="">Mother's Contact : {CurrentUserData?.motherPhone} </label>
                  <label htmlFor="">Email : {CurrentUserData?.email}  </label>
              </div>
              <div className="other-info">
                  <h3>OTHER INFO</h3>
                  <label htmlFor="">Status : {CurrentUserData?.userStatus} </label>
              </div>
              
          </div>
          <br />
          <div className="edit-info-notice">
                <label htmlFor="">If have wish to make any changes to your personal details, Please report to the school Admin.</label>
              </div>
      </div>
    </div>
        </div>
      </div>
  )
}

export default StudentDashboard