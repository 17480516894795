import axios from "axios";
import { createContext, useEffect, useState  } from "react";


const StageContext = createContext();

const StageContextProvider = ({children})=>{

const [selectedStage, setSelectedStage] = useState('-4')

  return (
    <StageContext.Provider value={
      {
        selectedStage,
        setSelectedStage,
        }}>
      {children}
    </StageContext.Provider>
  )
}

export {StageContext, StageContextProvider}